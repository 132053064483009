.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.suit-row {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

button {
  width: 50px; 
  height: 50px;
  margin: 5px; 
  font-size: 16px;
  background-color: #f0f0f0; 
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer; 
}

button:hover {
  background-color: #e0e0e0; 
}

.suji-item {
  padding: 5px;
  margin: 2px;
  display: inline-block;
  cursor: pointer;
}

.inactive {
  color: #ccc;
}

.reset-button {


  padding: 10px 20px;
  margin-top: 20px;
  font-size: 16px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.reset-button:hover {
  background-color: #d32f2f;
}

.tile-button.disabled {
  background-color: #ccc; /* 無効なボタンの背景色 */
  color: #666; /* 無効なボタンのテキスト色 */
}
.tile-button.pressed {
  background-color: #a1a1a1; /* 押された状態の背景色 */
  color: white; /* 押された状態のテキスト色 */
}

.button-mid {
  width: 120px; 
}

.mode-label {
  margin-bottom: 10px;
}

.mode-toggle-button {
  margin-left: 20px; /* ボタンとテキストの間に余白を設ける */
}